<template>
  <v-container>
    <h2>Allgemeine Geschäftsbedingungen</h2>

    <h3>ZSM IT UG (haftungsbeschränkt)</h3>
    <p>
    Geschäftsführer: Felix Saup, Yuhong Zhao<br>
    Bleichstr. 59<br>
    63065 Offenbach am Main
    </p>

    <p>Soweit im folgenden Text personenbezogene Bezeichnungen nur in geschlechtsspezifischer Form angeführt sind, beziehen
      sie sich auf Frauen und Männer in gleicher Weise.</p>

    <h3>Begriffsdefinitionen</h3>
    <ul style="list-style: none">
      <li><h4>Plattform</h4>
        <p>Die durch die ZSM IT UG (haftungsbeschränkt) betriebene Bestellplattform.</p>
      </li>
      <li><h4>Restaurant</h4>
        <p>Ein Restaurant, dass die Plattform nutzt um für den Verkauf seiner Produkte Verträge abzuschließen sowie die daraus entstehenden Zahlungen abzuwickeln.</p>
      </li>
      <li><h4>Kunde</h4>
        <p>Eine juristische oder natürliche Person, die mittels der Plattform eine Bestellung an ein Restaurant übermittelt.</p>
      </li>
      <li><h4>Produkt</h4>
        <p>Eine Speise, Getränk oder vergleichbares Produkt, welches das Restaurant zubereitet und verkauft sowie auf der Plattform anbietet.</p>
      </li>
      <li><h4>Bestellung</h4>
        <p>Eine Bestellung von Produkten eines Kunden bei einem Restaurant, die über die Plattform zustande gekommen ist.</p>
      </li>
    </ul>

    <h3>Geltungsbereich</h3>
    <ol>
      <li>Diese AGB gelten für Kunden, die die Plattform zum Aufgeben von Bestellungen nutzen.</li>
      <li>Durch die Aufgabe einer Bestellung über die Plattform kommt ein Vetrag zwischen Kunde und Restaurant über die Lieferung der gewählten Produkte zustande.</li>
      <li>Die ZSM IT UG ist nicht Teil dieses Vertrags. Ebenso ist die Plattform nicht für das Angebot des Restaurants verantwortlich. Diese AGB haben keinen Einfluss auf den Vertrag.</li>
      <li>Gegebenenfalls gelten bei der Aufgabe von Bestellungen die AGB des Restaurants.</li>
    </ol>

    <h3>Vertrag zwischen Restaurant und Kunden</h3>
    <ol>
      <li>
        Der Vertrag zwischen Restaurant und Kunden kommt zustande, sobald der Kunde die Bestellung auf der Plattform aufgibt.
      </li>
    </ol>

    <h3>Zahlung</h3>
    <ol>
      <li>Sofern keine Barzahlung ausgewählt wurde, führt die Plattform einen Online-Bezahlvorgang zwischen Kunden und Restaurant aus.</li>
      <li>Sollte eine Rückerstattung erforderlich werden, ist das Restaurant für diese Verantwortlich. Wenn die Zahlung über die Plattform abgewickelt wurde, veranlasst die Plattform eine Rückerstattung über das selbe Zahlungsmittel.</li>
    </ol>

    <h3>Haftung</h3>
    <ol>
      <li>Das Restaurant haftet alleinig für die von ihm eingestellten Inhalte wie Produkte, Produktdetails, Liefergebiete, Bilder, Restaurant-informationen und alle weiteren vom Restaurant einstellbaren Inhalte.</li>
      <li>Die Plattform haftet nicht für vom Restaurant einstellbare Inhalte.</li>
    </ol>

    <h3>Datenschutz</h3>
    <ol>
      <li>Um ihre Dienstleistungen ordnungsgemäß anbieten zu können, erhebt, speichert und verarbeitet die Plattform Daten des Kunden. Dabei werden alle gesetzlichen Bestimmungen beachtet.</li>
      <li>Die Plattform ist berechtigt die für das Ausführen der Bestellung notwendigen Daten an das Restaurant weiterzugeben.</li>
      <li>Genaue Angaben zum Datenschutz lassen sich der Datenschutzerklärung entnehmen.</li>
    </ol>


  </v-container>
</template>

<script>
export default {
  name: "tac"
}
</script>

<style scoped>

</style>